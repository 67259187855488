<template>
  <div class="content-wrapper">
    <div class="content-wrapper register-page">
      <div class="login-box">
        <div class="login-logo">
          <img :src="this.$client_picture_baseurl + 'logo.png'" width="100px" height="100px" alt="" class="brand-image img-circle elevation-3 mb-4" style="opacity: 1.0"><br/>
          <router-link to="/login"><b>{{ this.client.appname }}</b></router-link><skeleton-loader-vue v-show="skeleton" class="skeleton" type="rect" :rounded="true" :width="200" :height="51" animation="fade" />
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div v-show="verified" class="card-body login-card-body">
            <p class="login-box-msg">Change Password</p>
            <br>
            <form @submit.prevent="handleSubmit">
              <div class="form-group" :class="{'has-error':$v.password.$error,'has-success':!$v.password.$invalid }">
                  <div class="input-group mb-3">
                      <input @keydown.enter="focusNext" id="input-password" type="password" class="form-control" v-model.lazy="$v.password.$model" :class="{'is-invalid':$v.password.$error,'is-valid':!$v.password.$invalid }" placeholder="Password">
                      <div class="input-group-append">
                          <div class="input-group-text">
                              <span :class="{'is-invalid':$v.password.$error,'is-valid':!$v.password.$invalid }" class="fa fa-lock"></span>
                          </div>
                      </div>
                      <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your password is valid.</div>
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.password.required"><i class="fa fa-exclamation-circle"></i> Password is required.</span>
                          <span class="help-block" v-if="!$v.password.minLength"><i class="fa fa-exclamation-circle"></i> Password must have at least {{$v.password.$params.minLength.min}} letters.</span>
                      </div>
                  </div>
              </div>

              <div class="form-group" :class="{'has-error':$v.password_confirm.$error,'has-success':!$v.password_confirm.$invalid }">
                  <div class="input-group mb-3">
                      <input @keydown.enter="focusNext" id="input-password-confirm" type="password" class="form-control" v-model.lazy="$v.password_confirm.$model" :class="{'is-invalid':$v.password_confirm.$error,'is-valid': (password != '') ? !$v.password_confirm.$invalid : '' }" placeholder="Re-type Password">
                      <div class="input-group-append">
                          <div class="input-group-text">
                              <span class="fa fa-lock"></span>
                          </div>
                      </div>
                      <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your passwords are identical.</div>
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.password_confirm.required"><i class="fa fa-exclamation-circle"></i> Passwords mustbe identical.</span>
                      </div>
                  </div>
              </div>

              <div class="row">
                <div class="col-12 mb-2">
                    <div class="icheck-primary">
                    <input type="checkbox" id="agree" @click="toggleAgree" v-model="agree">
                    <label for="agree"> &nbsp;Confirm changes</label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                  <button type="submit" :disabled="$v.$invalid || !this.agree || loading" class="btn btn-primary btn-block"><i v-show="loading" class="spinner-border spinner-border-sm"></i> Submit</button>
                </div>
                <!-- /.col -->
              </div>
            </form>

            <p class="mb-1">
              <router-link to="/login">Proceed to login page</router-link>
            </p>
            <p class="mb-0">
              <router-link to="/register" class="text-center">Register a new membership</router-link>
            </p>
          </div>
          <div v-show="!verified && !loading" class="card-body login-card-body">
            <p class="login-box-msg"><i class="fa fa-times-circle text-danger"></i> Invalid reset token.<br>Redirecting to <router-link to="/login">login</router-link> page in {{ countDown }} seconds...</p>
          </div>
          <div v-show="!verified && loading" class="card-body login-card-body">
            <p class="login-box-msg"><i class="spinner-border spinner-border-sm"></i> Verifying Reset Token...</p>
          </div>
          <!-- /.login-card-body -->
        </div>
        <small v-if="!skeleton" class="d-flex justify-content-center text-secondary mt-3">Version {{ this.client.version }}</small>
        <span v-else class="d-flex justify-content-center text-secondary mt-3"><skeleton-loader-vue type="rect" :rounded="true" :width="60" :height="12" animation="fade" /></span>
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/Loader'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Reset',

  components: {
    VueSkeletonLoader,
  },

  data() {
    return {
      countDown : 10,
      client: {},
      password: '',
      password_confirm: '',
      loading: true,
      verified: false,
      agree: false,
      skeleton: true,
    }
  },

  validations: {
        password: {
            required,
            minLength: minLength(8)
        },
        password_confirm: {
            sameAsPassword: sameAs('password')
        }
    },

  components: {
    Loader
  },

  methods: {

    countDownTimer() {
        if(this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        } else {
            this.$router.push('/login');
        }
    },

    toggleAgree () {
        if (this.agree == false) {
            this.agree = true;
        } else {
            this.agree = false;
        }
    },

    async verifyToken() {
      this.loading = true;

      await axios.post('api/verify-reset-token/' + this.$route.query.token).then((response) => {
        if(response.data.verified) {
            this.loading = false;
            this.verified = true;

            //proceed
        } else {
            this.$swal.fire({
                icon: 'error',
                title: 'Invalid reset token code',
            });

            this.loading = false;
            this.verified = false;
            this.countDownTimer();
        }  
        }).catch(()=>{
            this.$swal.fire({
                icon: 'error',
                title: 'Invalid reset token code',
            })

            this.loading = false;
            this.verified = false;
            this.countDownTimer();
        });
    },

    async handleSubmit() {
      this.loading = true;

      await axios.post('api/reset', {
        password: this.password,
        password_confirm: this.password_confirm,
        token:this.$route.query.token
      }).then((response) => {
        this.$swal.fire({
          icon: 'success',
          title: response.data.message,
        })
        this.loading = false;
        this.$router.push('/login');
      }).catch((err) => {
        this.$swal.fire({
          icon: 'error',
          title: err.response.data.message,
        })
        this.loading = false;
      });
    }
  },

  async mounted () {
    this.$Progress.start();
    await axios.get('api/client').then(({ data }) => (
      this.client = data,
      this.skeleton = false
    ));
    setTimeout(() => {
        this.verifyToken();
    }, 4000);
    this.$Progress.finish();
  }

}
</script>

<style scoped>
.content-wrapper {
  margin-left: 0 !important;
  min-height: 0px !important;
}
.log-box {
  width: 360px;
  margin: 0 auto 0;
  padding-top: 10%;
}
.content-wrapper {
  margin-left: 0 !important;
}
.login-box-msg, .register-box-msg {
    padding: 0 20px 0px !important;
}
.skeleton {
  width: 100% !important;
}
</style>
