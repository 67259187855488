<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">

    <!-- Modals -->
    <!-- payment modal -->
    <div class="modal fade" data-backdrop="static" id="paymentmodal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <form @submit.prevent="viewmode ? viewPayment() : savePayment()" @keydown="form_payment.onKeydown($event)">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title" v-show="!viewmode"><i class="fa fa-credit-card-alt"></i> OR No. <span v-if="form_payment.or_no=='DEPLETED'" class="text-danger">{{ form_payment.or_no }}</span><span v-else>{{ form_payment.or_no }}</span> (New Transaction)</h1>
              <h1 class="card-title modal-title" v-show="viewmode"><i class="fa fa-credit-card-alt"></i> OR No. 67500</h1> 
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row-aligncenter">
                    <iframe id="print-iframe" width="0" height="0" frameBorder="0"></iframe>
                      <div class="card-body table-responsive p-0" id="printReceipt">
                        <div v-if="loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-info alert-dismissible">
                            <h5><i class="spinner-border spinner-border-sm"></i> Loading . . .</h5>
                            Checking available OR for the new transaction.
                          </div>
                        </div>
                        <div v-show="this.form_payment.or_no=='DEPLETED' && !loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-danger alert-dismissible">
                            <h5><i class="icon fa fa-exclamation-triangle"></i> OR has depleted</h5>
                            Please call Supervisor to register new OR booklet.
                          </div>
                        </div>
                        <div v-show="this.form_payment.or_no!='DEPLETED' && !loading_check_or" class="card-body table-responsive p-0">
                          <div class="alert alert-primary alert-dismissible">
                            <h5><i class="icon fa fa-check"></i> OR assigned</h5>
                            This transaction is assigned to OR No. {{ form_payment.or_no }}.
                          </div>
                        </div>
                        <table class="table borderless col-12 mb-2">
                          <tbody>
                            <tr> 
                              <td class="p-0" colspan="2">
                                <h3 class="text-secondary">OR No. <span v-if="form_payment.or_no=='DEPLETED'" class="text-danger">{{ form_payment.or_no }}</span><span v-else> {{ form_payment.or_no }}</span></h3>
                                <b>Payment Date:</b> {{ form_payment.or_date | customdate2 }}<br/>
                                <b>Customer Name </b><b class="text-danger">(Required)</b>: <input v-model.lazy="form_payment.consumer_name" class="form-control" type="text" size="50" />
                                <b>Address </b><b class="text-danger">(Required)</b>: <input v-model.lazy="form_payment.consumer_location" class="form-control mb-3" type="text" size="30" />
                                <!--
                                <b>Sr.Citizen TIN:</b> {{ form_payment.senior_no }}<br/>
                                <b>OSCA/PWD ID No.:</b> {{ form_payment.pwd_no }}<br/>
                                -->
                              </td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Membership Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_membership" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Connection Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_connection" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Reconnection Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_reconnection" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Service Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_service" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Threading Fee</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_threading" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Water Meter</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_meter" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Materials</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_materials" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr>
                              <td class="p-0"><b>Others</b></td>
                              <td class="p-0" style="border-bottom: 1px solid #000000 !important;" align="right"><input v-model.lazy="form_payment.fee_others" v-money="money" class="inputreceipt form-control" type="text" size="30" :readonly="viewmode" /></td>
                            </tr>
                            <tr> 
                              <td class="p-0" colspan="2">
                                <br/>
                                <span class="big"><b>Total amount paid: <mark>{{ getTotalAmountPaid | toMoney }}</mark></b></span><br/>
                                <b>Amount in words:</b> {{ form_payment.fee_total | toWordsPesos }} {{ form_payment.fee_total | toWordsCentavos }}<br/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
 
                      </div>
                  </div>
                    
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row col-12 p-0 m-0">
                <div class="col-8 p-0">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  <button type="button" :disabled="this.form_payment.or_no=='DEPLETED'||loading_pending_bills||loading_check_or" @click="cancelOr(form_payment.or_no)" v-show="!viewmode" class="btn btn-default bg-danger">Cancel this OR</button>
                </div>
                <div class="text-right col-4 p-0">

                  <button type="button" v-show="viewmode"
                  onclick="printJS({
                    printable: 'printReceipt', 
                    type:'html',
                    style: 'html {transform: scale(.6);transform-origin: left; .small {font-size: 5px !important;}} body{position:absolute!important;top:0!important;left:0!important;} #printReceipt {position:absolute!important;top:0!important;left:0!important;overflow-y:visible!important;overflow-x:visible !important;} #printReceipt::-webkit-scrollbar {display: none;}',
                    targetStyles: ['*']
                    })"  
                  class="btn btn-success"><i class="fa fa-print mr-1"></i> Print Receipt</button>&nbsp; <!-- @click="print"  onclick="print()"  v-print="'#printReceipt'" -->
                  
                  <button type="submit" :disabled="this.form_payment.or_no=='DEPLETED'||loading_pending_bills||loading_check_or||this.form_payment.consumer_name==''||this.form_payment.consumer_location==''" v-show="!viewmode" class="btn btn-info"><i class="fa fa-chevron-circle-right"></i> Submit</button>
                </div>
            </div>
          </div>

          </form>

        </div>
      </div>
    </div>


      <!-- receipt modal -->
      <div class="modal fade" data-backdrop="static" id="receiptmodal" tabindex="-1" role="dialog" aria-labelledby="receiptModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="max-width: 950px !important;">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>OR No. {{ form_payment.or_no }}</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @keydown="form_payment.onKeydown($event)">
            <div class="modal-body">
              <div class="container-fluid bg-white">
                <div class="row">
                  <div class="col-sm-6 border-right">
                    <div class="card-body table-responsive p-0" id="printReceiptCopy">
                      <table class="table borderless col-12" >
                            <tbody>
                              <tr>
                                <td align="center" class="p-0"><img :src="require('@/assets/receipt-header-bw.png')" width="100%" /></td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table borderless col-12 mb-2">
                            <tbody>
                              <tr>
                                <td width="50%" align="left" class="p-0 pt-3"><h3><b><u>OR_COPY</u></b></h3></td>
                                <td width="50%" align="right" class="p-0"><h3><b>NO. {{ form_payment.or_no }}</b></h3>Date: <u>{{ form_payment.or_date | customdate2 }}</u></td>
                              </tr>
                              <tr> 
                                <td class="p-0" colspan="2">
                                  RECEIVED from <u>{{ form_payment.consumer_name | bigcaps}}</u> address at <u>{{ form_payment.consumer_location }}</u> the sum of <u>{{ getTotalAmountPaid | toWordsPesos }} {{ getTotalAmountPaid | toWordsCentavos }}</u>
                                </td>
                              </tr>
                              <tr>
                                <td>In partial/full payment for:</td><td align="center">AMOUNT</td>
                              </tr>
                              <tr>
                                <td class="p-0">Membership Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_membership }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Connection Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_connection }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Reconnection Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_reconnection }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Service Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_service }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">Water Consumption Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">{{ xconvertFloat(trxn.amountdue) - xconvertFloat(trxn.surcharge) + xconvertFloat(trxn.discount)  | toMoney }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">Surcharge</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">{{ trxn.surcharge }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Threading Fee</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_threading }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Water Meter</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_meter }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Materials</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_materials }}</td>
                              </tr>
                              <tr>
                                <td class="p-0">Others</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center">{{ form_payment.fee_others }}</td>
                              </tr>
                              <tr v-show="this.form_payment.trxns.length == 1">
                                <td class="p-0">SC/PWD Discount</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center" v-for="trxn in this.form_payment.trxns" :key="trxn.id">{{ trxn.discount }}</td>
                              </tr>
                              <tr >
                                <td class="p-0"><br/>Total Amount Paid</td>
                                <td width="80%" class="p-0" style="border-bottom: 1px solid #000000 !important;" align="center"><br/><b>{{ getTotalAmountPaid | toMoney }}</b></td>
                              </tr>
                            </tbody>
                          </table>

                          <table v-show="this.form_payment.trxns.length != 1" class="bordered col-12" style="width:100%;border-spacing: 0;">
                            <thead>
                              <tr>
                                <!--<td style="border: 1px solid #989898 !important;"><b>Id</b></td>-->
                                <td style="border: 1px solid #989898 !important;"><b>Reading</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Prev.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Pres.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Con.</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Discount</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Surcharge</b></td>
                                <td style="border: 1px solid #989898 !important;"><b>Amt.</b></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                                <!--<td style="border: 1px solid #989898 !important;">{{ trxn.id }}</td>-->
                                <td style="border: 1px solid #989898 !important;"></td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.pres_reading }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.prev_reading }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.consumption }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.discount }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.surcharge }}</td>
                                <td style="border: 1px solid #989898 !important;">{{ trxn.amountdue }}</td>
                              </tr>
                            </tbody>
                          </table>


                          <table v-show="this.form_payment.trxns.length == 1" class="table bordered p-0" style="width:75%;border-spacing: 0;">
                            <tbody v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                              <tr>
                                <td align="left" class="p-0  border border-dark"><b>Present Reading</b><span class="float-right">-&nbsp;</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><b>Previous Reading</b><span class="float-right">-&nbsp;</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><b>Water Consumed</b><span class="float-right">-&nbsp;</span></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><small>Form of Payment:</small></td>
                              </tr>
                              <tr>
                                <td align="left" class="p-0 border border-dark"><small>Cash</small>&nbsp;&#9645;&nbsp;&nbsp;<small>Check</small>&nbsp;&#9645;</td>
                              </tr>
                            </tbody>
                          </table>
                              

                          <table class="table col-12" style="width:100%;margin: 0 auto;">
                            <tr>
                              <td width="50%" class="p-0" style="border-top: 1px solid #FFFFFF;">
                              <table class="table bordered p-0" style="width:220px;border-spacing: 0;">
                                <tr>
                                  <td colspan="2" align="left" class="p-1  border border-dark"><small><b>Sr.Citizen TIN:</b></small><br/>{{ form_payment.senior_no }}&nbsp;</td>
                                </tr>
                                <tr>
                                  <td align="left" class="p-1 border border-dark"><small><b>OSCA/PWD ID No.</b></small><br/>{{ form_payment.pwd_no }}&nbsp;</td>
                                  <td align="left" class="p-1 border border-dark"><small><b>Signature:</b></small><br/>&nbsp;</td>
                                </tr>
                              </table>
                              </td>
                              <td width="50%" align="center" valign="center" style="border-top: 1px solid #FFFFFF;">
                                <br/><br/>
                                By: <u>&nbsp;{{ form_payment.cashier }}&nbsp;</u><br/>
                                <small>Cashier/Authorized Representative</small>
                              </td>
                            </tr>
                          </table>
                          <br/>
                          <span><b>This is a receipt copy only.</b></span>
                          <!--
                          <table class="table borderless col-12" style="width:100%;margin: 0 auto;">
                            <tr>
                              <td align="center" class="p-0"><img :src="require('@/assets/receipt-footer.png')" width="100%" /></td>
                            </tr>
                          </table>
                          -->
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card-body table-responsive p-0" id="printReceiptOrig" style="height:950px !important">
                      <span class="receiptfont" style="position: absolute;top:103px;left:238px;font-family: monospace;font-size: 18px;"><b>{{ form_payment.or_date | customdate2 }}</b></span>                      
                      <table class="table borderless col-12" width="350px" style="margin-top: 123px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><span style="margin-left:142px;"><b>{{ form_payment.consumer_name | bigcaps | trimright(20)}}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><span v-if="form_payment.consumer_name && form_payment.consumer_name.length > 21" style="margin-left:-7px;"><b>{{ form_payment.consumer_name | bigcaps | trimleft(20) | trimright(35) }}</b></span><span v-else style="margin-left:-7px;"><b>&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -5px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 18px;"><b><span style="margin-left:61px;">{{ form_payment.consumer_location | bigcaps | trimright(28) }}</span></b></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -2px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-left:61px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | smallcaps | trimright(28) }}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -2px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-left:-7px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | trimleft(28) | trimright(35) | allsmallcaps}}</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -4px">
                        <tbody>
                          <tr>
                            <td align="left" class="p-0" style="font-family: monospace;font-size: 15px;"><span v-if="this.totalamtpd.length > 63" style="margin-left:-7px;"><b>{{ getTotalAmountPaid | moneyToWordsPesos | trimleft(63) | trimright(35) | allsmallcaps }}</b></span><span v-else style="margin-left:-7px;"><b>&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: 25px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0 mr-2" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_membership }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_connection }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_reconnection }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_service }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;" v-for="trxn1 in this.form_payment.trxns" :key="trxn1.id"><span style="margin-right:10px;"><b>{{ xconvertFloat(trxn1.amountdue) - xconvertFloat(trxn1.surcharge) + xconvertFloat(trxn1.discount)  | toMoney }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;" v-for="trxn2 in this.form_payment.trxns" :key="trxn2.id"><span style="margin-right:10px;"><b>{{ trxn2.surcharge }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_threading }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_meter }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_materials }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ form_payment.fee_others }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: -8px">
                        <tbody>
                          <tr v-if="this.form_payment.trxns.length != 1">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>0.00&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                          <tr v-else v-for="trxn3 in this.form_payment.trxns" :key="trxn3.id">
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 15px;"><span style="margin-right:10px;"><b>{{ trxn3.discount }}&nbsp;&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table borderless col-12" width="350px" style="margin-top: 7px">
                        <tbody>
                          <tr>
                            <td align="right" class="p-0" style="font-family: monospace;font-size: 18px;"><span style="margin-right:10px;"><b>{{ getTotalAmountPaid | toMoney }}&nbsp;&nbsp;&nbsp;</b></span></td>
                          </tr>
                        </tbody>
                      </table>

                      <table v-show="this.form_payment.trxns.length != 1" class="bordered col-12" width="350px" style="border-spacing: 0;position:absolute;top:498px;">
                        <thead>
                          <tr>
                            <!--<td style="border: 1px solid #989898 !important;"><b>Id</b></td>-->
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Reading</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Prev.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Pres.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Con.</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Discount</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Surcharge</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>Amt.</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="trxn in this.form_payment.trxns" :key="trxn.id">
                            <!--<td style="border: 1px solid #989898 !important;">{{ trxn.id }}</td>-->
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b></b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.pres_reading }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.prev_reading }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.consumption }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.discount }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.surcharge }}</b></td>
                            <td style="border: 1px solid #989898 !important;font-family: 'saxMono', Arial;font-size:12px;"><b>{{ trxn.amountdue }}</b></td>
                          </tr>
                        </tbody>
                      </table>

                      <div v-show="this.form_payment.trxns.length == 1">
                      <span v-for="trxn4 in this.form_payment.trxns" :key="trxn4.id">
                        <span style="position: absolute;top:516px;left:120px;font-family: monospace;"><b>-</b></span>
                        <span style="position: absolute;top:533px;left:120px;font-family: monospace;"><b>-</b></span>
                        <span style="position: absolute;top:550px;left:120px;font-family: monospace;"><b>-</b></span>
                      </span>
                      </div>

                      <span style="position: absolute;top:620px;left:10px;font-family: monospace;"><b>{{ form_payment.senior_no  | bigcaps }}</b></span>
                      <span style="position: absolute;top:650px;left:10px;font-family: monospace;"><b>{{ form_payment.pwd_no  | bigcaps }}</b></span>
                      <span style="position: absolute;top:620px;left:185px;width:300px;font-family: monospace;"><b>{{ form_payment.cashier | bigcaps | truncate(20, '') }}</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-4 p-0">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                  </div>
                  <div class="text-right col-8 p-0">
                    <button type="button" onclick="printJS({
                      printable: 'printReceiptCopy', 
                      type:'html',
                      style: 'html {transform: scale(.7);transform-origin: left; .small {font-size: 5px !important;}} body{position:absolute!important;top:0!important;left:0!important;} #printReceiptCopy {position:absolute!important;top:0!important;left:0!important;overflow-y:visible!important;overflow-x:visible !important;} #printReceiptCopy::-webkit-scrollbar {display: none;}',
                      targetStyles: ['*']
                      })" class="btn btn-success"><i class="fa fa-print mr-1"></i> Print Copy</button>&nbsp;

                    <button type="button" class="btn btn-success ml-1 mr-1" @click="printOR();"><i class="fa fa-print mr-1"></i>  Print OR</button>  
                  </div>
              </div>
            </div>
            </form>

          </div>
        </div>
      </div>

    <!-- Modals -->

    
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-8">
            <h1 class="m-0">
                <b>OTHER FEES</b> &#8231; Non-consumer
            </h1>
          </div><!-- /.col -->
          <div class="col-sm-4">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item">Consumers</li>
              <li class="breadcrumb-item active">Account</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row d-print-none">
          
        </div>
        <!-- /.row -->
        <div class="row">
          <div class="col-lg-12">
            <button v-show="user.role === 'Cashier' || user.role === 'Supervisor' || user.role === 'Admin'" type="button" @click="newPayment()" class="btn btn-block btn-primary btn-lg btn-lg mt-2 mb-4"><b><i class="fa fa-money mr-2"></i> PAY NOW</b></button>
            <div class="card">
              <Loader v-if="loading"  style="height: 100%" />
              <div class="card-header">
                <h1 class="card-title mt-1">Transaction History</h1>
                <div class="card-tools">
                  <div class="input-group mb-0">
                    <div class="mr-0">
                      <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{this.transaction_year}}
                      </button>
                      <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                        <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadDataTransaction(year)">{{ year }}</li>
                      </ul>
                    </div>
                  </div>
                </div>  
              </div>
              <div v-if="loading" class="card-body p-0">
                <table class="table table-striped table-hover">
                  <tr v-for="index in 10" :key="index">
                    <td class="text-left">
                      <skeleton-loader-vue class="mb-1" type="rect" :rounded="true" :width="220" :height="25" animation="fade" />
                      <skeleton-loader-vue class="mb-1" type="rect" :rounded="true" :width="180" :height="21" animation="fade" />
                      <skeleton-loader-vue class="" type="rect" :rounded="true" :width="300" :height="18" animation="fade" />
                    </td>
                    <td class="text-center"><skeleton-loader-vue class="mb-1 d-none d-lg-block" type="rect" :rounded="true" :width="190" :height="32" animation="fade" /></td>
                    <td class="text-right"><skeleton-loader-vue class="mb-1 d-none d-lg-block" type="rect" :rounded="true" :width="120" :height="32" animation="fade" /></td>
                  </tr>
                </table>
              </div>
              <div v-else class="card-body p-0">
                <table class="table table-striped table-hover"> 
                  <div v-if="transactions.total==0" class="card-body p-0">
                    <div class="row justify-content-center mt-5">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fill-rule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>                  
                    </div>
                    <div class="row justify-content-center mb-5 text-muted">
                      <p>No records found</p>
                    </div>
                  </div>
                  <tbody>
                    <tr v-for="trxn in transactions.data" :key="trxn.id" >
                      <td  class="text-left">
                        <b>{{ trxn.created_at | moment("YYYY-MMM-D ") }}</b><span class="time"> <i class="fa fa-clock-o"></i> {{ trxn.created_at | moment("h:mm a") }}</span><br/><span class="right badge badge-success">{{ trxn.consumer_name }}</span>
                        <span v-for="tx in trxn.transactions" :key="tx.id" >
                          <span class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>' + tx.reading_date + ': ' + tx.amountdue + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">{{ tx.reading_date }}</span>
                        </span>
                        <br/>
                        <span v-show="trxn.fee_membership!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Membership Fee: ' + trxn.fee_membership + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">MEM.</span>
                        <span v-show="trxn.fee_connection!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Connection Fee: ' + trxn.fee_connection + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">CONN.</span>
                        <span v-show="trxn.fee_reconnection!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Reconnection Fee: ' + trxn.fee_reconnection + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">RECONN.</span> 
                        <span v-show="trxn.fee_service!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Service Fee: ' + trxn.fee_service + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">SERV.</span>
                        <span v-show="trxn.fee_threading!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Threading Fee: ' + trxn.fee_threading + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">THREAD.</span>
                        <span v-show="trxn.fee_meter!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Water Meter: ' + trxn.fee_meter + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">METER</span>
                        <span v-show="trxn.fee_materials!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Materials: ' + trxn.fee_materials + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">MATER.</span>
                        <span v-show="trxn.fee_others!=0.00" class="right badge badge-warning mr-1 cursor" v-tooltip="{ content: '<b>Others: ' + trxn.fee_others + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }">OTHERS</span>
                      </td>
                      <td class="text-center"><h2 class="text-secondary d-none d-lg-block">OR No. {{ trxn.or_no }}</h2></td>
                      <td class="text-right"><h2 class="text-default d-none d-lg-block">₱{{ trxn.fee_total | toMoney }}</h2></td>
                      <td class="text-right"><button title="View receipt" type="button"  @click="viewReceipt(trxn)" class="btn btn-success btn-sm mt-2 ml-2" v-tooltip="{ content: '<b>View Receipt</b>', placement: 'left', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"><i class="fa fa-qrcode"></i></button></td>
                    </tr>
                  </tbody>
      
                </table>
              </div>
              <div v-show="transactions.total!=0" class="card-footer">
                <pagination class="pagination justify-content-center m-0" :data="transactions" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->


      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import Form from 'vform';
import moment from 'moment';
import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'
import printJS from "print-js";
import store from '@/vuex';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Otherfees',

  components: {
    Loader, VueSkeletonLoader,
  },

  data() {
    return {
      skeleton: true,
      viewmode: false,
      readingeditmode: false,
      loading: false,
      loading_pending_bills: false,
      loading_check_or: false,
      loading_consumer: false,
      reading_update: false,
      confirm : false,
      year: '',
      todaydate: '',
      totalamtpd: 0,
      disconnection: '',
      reading_year: '',
      transaction_year: '',
      consumer : {},
      readings : {},
      readings_all : {},
      transactions: {},
      search_reading : '',
      consumertype : '',
      surcharge: '',
      senior: '',
      pwd: '',
      res_bracket1: '',
      res_bracket2: '',
      res_bracket3: '',
      res_bracket4: '',
      res_bracket5: '',
      res_bracket6: '',
      com_bracket1: '',
      com_bracket2: '',
      com_bracket3: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      form_payment: new Form({
        fee_membership: 0,
        fee_connection: 0,
        fee_reconnection: 0,
        fee_service: 0,
        fee_threading: 0,
        fee_meter: 0,
        fee_materials: 0,
        fee_others: 0,
        fee_total: 0,
        or_no: '',
        or_date: '',
        cashier: '',
        senior_no: '',
        pwd_no: '',
        consumer_id: 0,
        consumer_name: '',
        consumer_location: '',
        trxns: [{
                  id: '0',
                  reading_id: '0',
                  payment_id: '',
                  reading_date: '',
                  pres_reading: '0',
                  prev_reading: '0',
                  consumption: '0',
                  discount: '0.00',
                  surcharge: '0.00',
                  amountdue: '0.00',
                }],

      }),
      receipt: new Form({

      }),

    }
  },

  validations: {
    form_consumer: {
      Lastname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Firstname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Middlename: { maxLength: maxLength(60) },
      Extension: { maxLength: maxLength(10) },
      Address: { required,  minLength: minLength(2),  maxLength: maxLength(150) },
      Senior: { maxLength: maxLength(15) },
      Pwd: { maxLength: maxLength(15) },
      Consumertype: { required },
    }
  },

  methods: {
    convertToFloat(x) {
      if(!x) {
        return 0.00
      } else {
        
        return parseFloat(x.replace(/\$|,/g, ''));
      }
    },

    getFullname() {
      var mname = '';
      var ext = '';
      var fname = '';

      if (!this.consumer.Firstname) {
        fname = '';
      } else {
        fname = ', ' + this.consumer.Firstname;
      }

      if (this.consumer.Middlename) {
        mname = ' ' + this.consumer.Middlename.substring(0, 1) + '.';
      }

      if (this.consumer.Extension) {
        ext = ' ' + this.consumer.Extension;
      }

      return this.consumer.Lastname + ext + fname + mname
    },

    print(){

      /**/var style = document.createElement('style');
              style.innerHTML = "" +
                      " @media print { " +
                      " @page {size: A4 portrait;} #printReceipt{ bg-color:pink;} " +
                      "  }";
              window.document.head.appendChild(style);
            

    },

    computeReading: function() {
      var amount;
      var discount;


      if (this.form_reading.pres_reading === '' || this.form_reading.pres_reading < this.form_reading.prev_reading) {
        this.form_reading.consumption = 0;
        this.form_reading.currentcharge = 0;
        this.form_reading.amountdue = 0;
      } else {
        this.form_reading.consumption = this.form_reading.pres_reading - this.form_reading.prev_reading;
        amount = this.computeConsumption(this.form_reading.consumption)

        if (this.consumer.consumertype != 'Commercial/Industrial') {

          if (this.consumer.Senior) {
            var comp = (this.senior / 100) * amount;
            if (comp > 23.00) {
              discount = 23.00;
            } else {
              discount = (this.senior / 100) * amount;
            }
          } else if (this.consumer.Pwd) {
            var comp = (this.pwd / 100) * amount;
            if (comp > 23.00) {
              discount = 23.00;
            } else {
              discount = comp;
            }
          } else if (this.consumer.Senior && this.consumer.Pwd) {
            var comp = (this.senior / 100) * amount;
            if (comp > 23.00) {
              discount = 23.00;
            } else {
              discount = comp;
            }
          } else {
            discount = 0;
          }

        } else {
          discount = 0;
        }

        //console.log(discount);

        this.form_reading.amountdue = amount - discount;
        
        if(this.consumer.Active == 0)
        {
          this.form_reading.currentcharge = 0;
          this.form_reading.amountdue = 0;
        } else {
          this.form_reading.currentcharge = amount;
        }
      }
      
    },

    computeConsumption: function(water) {
      // For residential consumer types
      if (this.consumertype == 'Residential') {
        var res_bracket1_ceiling = parseFloat(this.res_bracket1);
        var res_bracket2_ceiling = (parseFloat(this.res_bracket2) * (20 - 10)) + res_bracket1_ceiling;
        var res_bracket3_ceiling = (parseFloat(this.res_bracket3) * (30 - 20)) + res_bracket2_ceiling;
        var res_bracket4_ceiling = (parseFloat(this.res_bracket4) * (40 - 30)) + res_bracket3_ceiling;
        var res_bracket5_ceiling = (parseFloat(this.res_bracket5) * (50 - 40)) + res_bracket4_ceiling;
        
        if (water >= 0 && water <= 10) {
          return parseFloat(this.res_bracket1);
        } else if (water >= 11 && water <= 20) {
          return (parseFloat(this.res_bracket2) * (water - 10)) + parseFloat(res_bracket1_ceiling);
        } else if (water >= 21 && water <= 30) {
          return (parseFloat(this.res_bracket3) * (water - 20)) + parseFloat(res_bracket2_ceiling);
        } else if (water >= 31 && water <= 40) {
          return (parseFloat(this.res_bracket4) * (water - 30)) + parseFloat(res_bracket3_ceiling);
        } else if (water >= 41 && water <= 50) {
          return (parseFloat(this.res_bracket5) * (water - 40)) + parseFloat(res_bracket4_ceiling);
        } else if (water >= 51) {
          return (parseFloat(this.res_bracket6) * (water - 50)) + parseFloat(res_bracket5_ceiling);
        }
      }
      // For commercial consumer types
      if (this.consumertype == 'Commercial/Industrial') {
        var com_bracket1_ceiling = parseFloat(this.com_bracket1);
        var com_bracket2_ceiling = (parseFloat(this.com_bracket2) * (1000 - 25)) + com_bracket1_ceiling;
        
        if (water >= 0 && water <= 25) {
          return parseFloat(this.com_bracket1);
        } else if (water >= 26 && water <= 1000) {
          return (parseFloat(this.com_bracket2) * (water - 25)) + parseFloat(com_bracket1_ceiling);
        } else if (water >= 1001) {
          return (parseFloat(this.com_bracket3) * (water - 1000)) + parseFloat(com_bracket2_ceiling);
        }
      }
    }, 

    computeSurcharge: function(fee, current) {
      var compute = (parseFloat(fee) / 100) * parseFloat(current);
      return compute.toString();
    },

    computeDiscount: function(fee, current, type) {
      var comp;
      comp = (fee / 100) * current

      if (type != 'Commercial/Industrial') {
        if (comp > 23) {
          return 23.00;
        } else {
          return comp;
        }

      } else {
        return comp;
      }
      
    },

    computeAmountdue: function(current, surcharge, discount ) {
      return current + surcharge - discount;
    },
    
    async setServiceStatus(id, status) {
      this.$Progress.start();
      await this.form_consumer.put('api/consumers/' + id +  '/status/' + status)
      .then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'Status updated'
        })
        this.$Progress.finish();
        this.loadDataConsumer();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'Status update failed'
        });
        this.$Progress.fail();
      })
    },

    total_due(dueparam) {
      if (!dueparam) {
        //
      } else {
        return dueparam.reduce((tot, val) => {
          var sum;
          //sum = tot + parseFloat(val.amountdue.replace(/,/g, ''));
          sum = tot + parseFloat(val.amountdue);
          return sum;
        }, 0);
      }
    },

    addTrxn(param_reading_id, param_reading_date, param_pres_reading, param_prev_reading, param_consumption, param_discount, param_currentcharge, param_due_at) {
        
        const current = new Date();
        const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
        var scharge;
        var compute_discount;
        var due_at = moment(String(param_due_at)).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');

        if(timestamp > due_at) {
          scharge = parseFloat(this.computeSurcharge(this.surcharge, param_currentcharge)).toFixed(2);
        } else {
          scharge = "0.00";
        }

        if(scharge != "0.00") {
          compute_discount = "0.00"
        } else {
          compute_discount = parseFloat(this.computeDiscount(param_discount, param_currentcharge, this.consumer.consumertype)).toFixed(2);
        }


        
        this.form_payment.trxns.push({
            id: param_reading_id,
            reading_date: moment(param_reading_date).format('YYYY-MM-DD'),
            pres_reading: param_pres_reading,
            prev_reading: param_prev_reading,
            consumption: param_consumption,
            discount: compute_discount,
            surcharge: scharge,
            currentcharge: param_currentcharge,
            amountdue:  parseFloat(parseFloat(param_currentcharge) + parseFloat(scharge) - parseFloat(compute_discount)).toFixed(2),
        })

        this.form_payment.trxns.sort((a, b) => Number(a.id) - Number(b.id));

    },

    // ####################### START PAYMENT METHODS ################################
    async newPayment() {
      this.viewmode = false;
      this.form_payment.reset();
      this.form_payment.clear();
      const current = new Date();
      const timestamp =  moment(String(current)).format('YYYY-MM-DD HH:mm:ss');
      this.form_payment.or_date = timestamp;
      this.form_payment.consumer_id = 0;
      this.form_payment.consumer_name = '';
      this.form_payment.consumer_location =  this.consumer.Location;
      this.form_payment.cashier = this.user.name;
      this.form_payment.senior_no = this.consumer.Senior;
      this.form_payment.pwd_no = this.consumer.Pwd;
      this.getActiveOR();
      $('#paymentmodal').modal('show');
    },

    viewPayment(transaction) {
      this.viewmode = true;
      this.form_payment.reset();
      this.form_payment.clear();
      this.form_payment.fill(transaction.payments);
      this.form_payment.pres_reading = transaction.pres_reading;
      this.form_payment.prev_reading = transaction.prev_reading;
      this.form_payment.consumption = transaction.consumption;
      $('#paymentmodal').modal('show');
    },

    viewReceipt(transaction) {
      this.form_payment.reset();
      this.form_payment.clear();
      this.form_payment.or_no = transaction.or_no;
      this.form_payment.or_date = transaction.created_at;
      this.form_payment.consumer_name = transaction.consumer_name;
      this.form_payment.consumer_location = transaction.consumer_location;
      this.form_payment.fee_connection = transaction.fee_connection;
      this.form_payment.fee_materials = transaction.fee_materials;
      this.form_payment.fee_membership = transaction.fee_membership;
      this.form_payment.fee_meter = transaction.fee_meter;
      this.form_payment.fee_others = transaction.fee_others;
      this.form_payment.fee_reconnection = transaction.fee_reconnection;
      this.form_payment.fee_service = transaction.fee_service;
      this.form_payment.fee_threading = transaction.fee_threading;
      this.form_payment.cashier = transaction.cashier;
      this.form_payment.senior_no = this.consumer.Senior;
      this.form_payment.pwd_no = this.consumer.Pwd;
      this.form_payment.trxns.splice(0, 1);

      for (let i = 0; i < transaction.transactions.length; i++) {
        this.form_payment.trxns.push(transaction.transactions[i]);
      }

      $('#receiptmodal').modal('show');
    },

    // Save new reading
    savePayment() {
      this.$Progress.start();
      this.form_payment.post('api/payments')
      .then(()=>{
            this.$swal.fire({
              icon: 'success',
              title: 'Payment processed'
            })
            this.$Progress.finish();
            //ask if cashier wants to print transaction
            this.loadDataTransaction();
            this.loadDataReading();
      }).catch(()=>{
            this.$swal.fire({
              icon: 'error',
              title: 'Payment failed'
            });
            this.$Progress.fail();
      });

      //this.viewmode = true;
      $('#paymentmodal').modal('hide')
      

    },

    // ####################### END PAYMENT METHODS ################################

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    // Get latest active OR for new payment
    async getActiveOR() {
      this.loading_check_or = true;
      await axios.get('api/booklets/or/' + this.user.id).then( 
      response => {
        // set form.prev_reading to zero (0) if axios has no return value
        if (!response.data || response.data.length === 0) {
          this.form_payment.or_no = 'DEPLETED';
        } else {
          this.form_payment.or_no = response.data.or_no;
        }
      }).catch(function (error) {
        console.log(error);
      });
      this.loading_check_or = false;
    },

    // Cancel OR
    cancelOr(orno) {
      swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cancel this OR',
          cancelButtonText: 'No'
          }).then((result)=>{

            if (result.value) {
              //send delete ajax tru api request to server
              this.form_payment.put('api/booklets/or/' + orno + '/Cancelled')
              .then(()=>{
                swal.fire(
                  'Processed!',
                  'OR successfully cancelled.',
                  'success'
                );
                this.getActiveOR();
              }).catch(()=>{
                swal.fire(
                  'Failed!', 
                  'There was something wrong', 
                  'warning'
                )
              });
            }  
        })
    },

    async layoutIframe() {
      const content = await document.getElementById('printBilling').innerHTML;
      let iFrame = document.getElementById('print-iframe');
      const printHtml = `<html>
				<head>
					<meta charset="utf-8">
					<title>Name Card</title>
				</head>
        <style>
            body {
              font-family: Monospace;
              font-size: 13px;
              width:240px;
            }
            td { font-size: 12px }
            .big {font-size: 15px !important;}
            .mid {font-size: 14px !important;}
            .rheader {font-size: 11px !important;}
            .hideinprint {
              display: none;
            }
        </style>
				<body>${content}</body>
			</html>`;
      iFrame.contentDocument.body.innerHTML = printHtml;
      iFrame.focus();
    },

    async samplePrint() {
      let iFrame = document.getElementById('print-iframe');
      iFrame.focus();
      //setTimeout(iFrame.contentWindow.print(), 2000)
      await iFrame.contentWindow.print();
    },

    printOR() {
      const content = document.getElementById('printReceiptOrig').innerHTML;
      let iFrame = document.getElementById('print-iframe');
      const printHtml = `<html>
				<head>
					<meta charset="utf-8">
					<title>Print OR</title>
				</head>
        <style>
            body {
              font-family: 'saxMono', Arial;
              font-size: 15px;
              width:240px;
            }
            td { font-size: 15px }
            .big {font-size: 30px !important;}
            .mid {font-size: 20px !important;}
            .hideinprint {              
              display: none;
            }
        </style>
				<body>${content}</body>
			</html>`;
      iFrame.contentDocument.body.innerHTML = printHtml;
      iFrame.focus();
      iFrame.contentWindow.print();
    },

    async loadDataConsumer() {
      this.$Progress.start();
      // load consumers data
      await axios.get('api/consumers/' + this.$route.params.id).then(({ data }) => (this.consumer = data));
      this.consumertype = this.consumer.Consumertype;
      this.$Progress.finish();
    },

    setYear(yr) {
      this.year = yr;
    },

    async loadDataReading(year) {
      const current = new Date();
      
      if(!year) {
        this.reading_year = current.getFullYear();
      } else {
        this.reading_year = year;
      }

      this.$Progress.start();
      // load readings data
      await axios.get('api/readings/' + this.$route.params.id + '/year/' + this.reading_year).then(({ data }) => (this.readings = data));
      this.$Progress.finish();
    },

    async loadDataTransaction(year) {
      const current = new Date();

      if(!year) {
        this.transaction_year = current.getFullYear();
      } else {
        this.transaction_year = year;
      }
      
      this.$Progress.start();
      await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      this.$Progress.finish();
    },

    
    async getResults(page = 1) {
      this.$Progress.start();
      this.loading = true;
      let query = this.search_reading;

      if(query == '') {
        await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year + '?page=' + page).then(({ data }) => { this.transactions = data; });
      } else {
        await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year +'&page=' + page).then(({ data }) => { this.transactions = data; }); 
      }
      this.$Progress.finish();
      this.loading = false;
    },

    async loadData() {
      this.loading = true;
      const current = new Date();
      this.transaction_year = current.getFullYear();
      await axios.get('api/payments/'+ this.$route.params.id + '/' + this.transaction_year).then(({ data }) => (this.transactions = data));
      this.loading = false;
      this.skeleton = false;
    },

    xconvertFloat(param) {
      return parseFloat(param);
    },

  },

  computed: {
    ...mapGetters(['user']),

    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => 2020 + index)
    },

    getTotalAmountPaid() {
      var total = 0;

      //var xamountdue = this.convertToFloat(this.form_payment.amountdue);
      var xmembership = this.convertToFloat(this.form_payment.fee_membership);
      var xconnection = this.convertToFloat(this.form_payment.fee_connection);
      var xreconnection = this.convertToFloat(this.form_payment.fee_reconnection);
      var xservice = this.convertToFloat(this.form_payment.fee_service);
      var xsurcharge = this.convertToFloat(this.form_payment.fee_surcharge);
      var xthreading = this.convertToFloat(this.form_payment.fee_threading);
      var xmeter = this.convertToFloat(this.form_payment.fee_meter);
      var xmaterials = this.convertToFloat(this.form_payment.fee_materials);
      var xothers = this.convertToFloat(this.form_payment.fee_others);
      
      total =  xmembership + xconnection + xreconnection + xservice + xsurcharge + xthreading + xmeter + xmaterials + xothers;

      if (isNaN(total)) {
        this.form_payment.fee_total = 0;
      } else {
        this.form_payment.fee_total = total.toFixed(2);
      }

      this.totalamtpd = this.$options.filters.moneyToWordsPesos(total)
      return total;
    },
  },

  mounted() {
    this.$Progress.start();
    const today = new Date();
    this.loadData();
    this.year = today.getFullYear();
    this.$Progress.finish();
  },
}
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
.card-title {
  font-size: 1.3rem !important;
}
.dropdown-item {
  cursor: pointer;
}
.custom-scrollbar {
  position: relative;
  height: 720px;
  overflow: auto;
  padding: 0;
}
#style-7::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-7::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-7::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}
.big {font-size: 21px !important;}
.mid {font-size: 21px !important;}
/*
@media print{ 
   .big {font-size: 220px !important; color: red;}
   h1 { color: red; }
   #printBilling {
     width:300px;
   }
} */
@media print {
  .receiptfont {
    font-family: Consolas;
  }

  .printed-div{
       display:block;
   }

   .logo-print{
       width:291px;
       height:109px;
       background-image: url('../assets/logo.png');
       background-repeat: no-repeat;
   }
  
}

@media all{
   printed-div{
       display:none;
   }
}

.logo-print{
    width:68px;
    height:69px;
    background-image: url('../assets/logo.png');
    background-repeat: no-repeat;
}

.dropdown-menu {
    min-width: 5rem;
}
.skeleton {
  width: 100% !important;
}
</style>
