<template>
  <div class="content-wrapper register-page">
    <div class="register-box">
        <div class="login-logo">
            <img :src="this.$client_picture_baseurl + 'logo.png'" width="100px" height="100px" alt="" class="brand-image img-circle elevation-3 mb-4" style="opacity: 1.0"><br/>
            <router-link to="/login"><b>{{ this.client.appname }}</b></router-link><skeleton-loader-vue v-show="skeleton" class="skeleton" type="rect" :rounded="true" :width="200" :height="51" animation="fade" />
        </div>

        <div class="card">
            <div class="card-body register-card-body">
            <p class="login-box-msg">Register a new account</p>

            <form @submit.prevent="handleSubmit" novalidate autocomplete="off">

                <div class="form-group" :class="{'has-error':$v.name.$error,'has-success':!$v.name.$invalid }">
                    <div class="input-group mb-3">
                        <input @keydown.enter="focusNext" type="text" class="form-control" v-model.lazy="$v.name.$model" :class="{'is-invalid':$v.name.$error,'is-valid':!$v.name.$invalid }" placeholder="Full name">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fa fa-user"></span>
                            </div>
                        </div>
                        <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your full name is valid.</div>
                        <div class="invalid-feedback">
                            <span class="help-block" v-if="!$v.name.required"><i class="fa fa-exclamation-circle"></i> Full name is required.</span>
                            <span class="help-block" v-if="!$v.name.minLength"><i class="fa fa-exclamation-circle"></i> Full name must have at least {{$v.name.$params.minLength.min}} letters.</span>
                            <span class="help-block" v-if="!$v.name.maxLength"><i class="fa fa-exclamation-circle"></i> Full name must not exeed to {{$v.name.$params.maxLength.max}} letters.</span>
                        </div>
                    </div>
                    
                </div>
      
                <div class="form-group" :class="{'has-error':$v.position.$error,'has-success':!$v.position.$invalid }">
                    <div class="input-group mb-3">
                        <input @keydown.enter="focusNext" type="text" class="form-control" v-model.lazy="$v.position.$model" :class="{'is-invalid':$v.position.$error,'is-valid':!$v.position.$invalid }" placeholder="Position">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span :class="{'is-invalid':$v.position.$error,'is-valid':!$v.position.$invalid }" class="fa fa-briefcase"></span>
                            </div>
                        </div>
                        <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your position is valid.</div>
                        <div class="invalid-feedback">
                            <span class="help-block" v-if="!$v.position.required"><i class="fa fa-exclamation-circle"></i> Position is required.</span>
                            <span class="help-block" v-if="!$v.position.minLength"><i class="fa fa-exclamation-circle"></i> Position must have at least {{$v.position.$params.minLength.min}} letters.</span>
                            <span class="help-block" v-if="!$v.position.maxLength"><i class="fa fa-exclamation-circle"></i> Position must not exeed to {{$v.position.$params.maxLength.max}} letters.</span>
                        </div>
                    </div>
                </div>
                

                <div class="form-group" :class="{'has-error':$v.email.$error,'has-success':!$v.email.$invalid }">
                    <div class="input-group mb-3">
                        <input @keydown.enter="focusNext" type="text" class="form-control" v-model.lazy="$v.email.$model" :class="{'is-invalid':$v.email.$error,'is-valid':!$v.email.$invalid }" placeholder="Email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fa fa-envelope"></span>
                            </div>
                        </div>
                        <div class="valid-feedback"><i class="fa fa-check-circle"></i> This email is available.</div>
                        <div class="invalid-feedback">
                            <span class="help-block" v-if="!$v.email.required"><i class="fa fa-exclamation-circle"></i> Email name is required.</span>
                            <span class="help-block" v-if="!$v.email.email"><i class="fa fa-exclamation-circle"></i> Email format is inavalid.</span>
                            <span class="help-block" v-if="!$v.email.uniqueEmail"><i class="fa fa-exclamation-circle"></i> Email has already taken.</span>
                        </div>
                    </div>
                </div>

                <div class="form-group" :class="{'has-error':$v.password.$error,'has-success':!$v.password.$invalid }">
                    <div class="input-group mb-3">
                        <input @keydown.enter="focusNext" id="input-password" type="password" class="form-control" v-model.lazy="$v.password.$model" :class="{'is-invalid':$v.password.$error,'is-valid':!$v.password.$invalid }" placeholder="Password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span :class="{'is-invalid':$v.password.$error,'is-valid':!$v.password.$invalid }" class="fa fa-lock"></span>
                            </div>
                        </div>
                        <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your password is valid.</div>
                        <div class="invalid-feedback">
                            <span class="help-block" v-if="!$v.password.required"><i class="fa fa-exclamation-circle"></i> Password is required.</span>
                            <span class="help-block" v-if="!$v.password.minLength"><i class="fa fa-exclamation-circle"></i> Password must have at least {{$v.password.$params.minLength.min}} letters.</span>
                        </div>
                    </div>
                </div>

                <div class="form-group" :class="{'has-error':$v.password_confirm.$error,'has-success':!$v.password_confirm.$invalid }">
                    <div class="input-group mb-3">
                        <input @keydown.enter="focusNext" id="input-password-confirm" type="password" class="form-control" v-model.lazy="$v.password_confirm.$model" :class="{'is-invalid':$v.password_confirm.$error,'is-valid': (password != '') ? !$v.password_confirm.$invalid : '' }" placeholder="Re-type Password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fa fa-lock"></span>
                            </div>
                        </div>
                        <div class="valid-feedback"><i class="fa fa-check-circle"></i> Your passwords are identical.</div>
                        <div class="invalid-feedback">
                            <span class="help-block" v-if="!$v.password_confirm.required"><i class="fa fa-exclamation-circle"></i> Passwords mustbe identical.</span>
                        </div>
                    </div>
                </div>
                
 
                <div class="row">
                <div class="col-12 mb-2">
                    <div class="icheck-primary">
                    <input type="checkbox" id="agree" @click="toggleAgree" v-model="agree">
                    <label for="agree"> &nbsp;I agree to the Terms and Conditions</label>
                    </div>
                </div>
                <!-- /.col -->
                <div class="col-12 mb-2">
                    <button type="submit" :disabled="$v.$invalid || !this.agree || loading" class="btn btn-primary btn-block pull-right"><i v-show="loading" class="spinner-border spinner-border-sm"></i>  Register</button>
                </div>
                <!-- /.col -->
                </div>
            </form>

            <router-link to="/login" class="text-center">I already have an account</router-link>
            </div>
            <!-- /.form-box -->
        </div><!-- /.card -->
        <small v-if="!skeleton" class="d-flex justify-content-center text-secondary mt-3">Version {{ this.client.version }}</small>
      <span v-else class="d-flex justify-content-center text-secondary mt-3"><skeleton-loader-vue type="rect" :rounded="true" :width="60" :height="12" animation="fade" /></span>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    name: 'Register',

    components: {
        VueSkeletonLoader,
    },

    data() {
        return {
            loading: false,
            client: {},
            name: '',
            position: '',
            email: '',
            password: '',
            password_confirm: '',
            showpassword: false,
            agree: false,
            skeleton: true,
        }
    },
    validations: {
        name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(40)
        },
        position: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(40)
        },
        email: {
            required,
            email,
            uniqueEmail(val) {

                if (val === '') return true
                return axios.get('api/exist/' + val)
                    .then(response => {
                    const accounts =  response.data //accounts.exist has to return true if existing or false if not existing in DB                
                    if (accounts.exist === true) {
                        return false; //unique is false if email existing is true
                    } else {
                        return true; //unique is true if email existing is false
                    }
                });
            }
        },
        password: {
            required,
            minLength: minLength(8)
        },
        password_confirm: {
            sameAsPassword: sameAs('password')
        }
    },

    methods: {

        async handleSubmit() {
            this.loading = true;

            this.$v.$touch();
            if(!this.$v.$invalid) {
                try {
                await axios.post('api/register',  {
                    name: this.name,
                    position: this.position,
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm
                });
                this.$router.push('/login');
                this.$swal.fire({
                    icon: 'success',
                    title: 'Please check you email to verify your account',
                    })
                    this.loading = false;
                } catch (e) {
                    this.error = e.response.data.message;
                    this.loading = false;
                }
            }
        },

        toggleShowPassword () {
            var show = document.getElementById('input-password')
            var showc = document.getElementById('input-password-confirm')
            if (this.showpassword == false) {
                this.showpassword = true;
                show.type = "text";
                showc.type = "text";
            } else {
                this.showpassword = false;
                show.type = "password";
                showc.type = "password";
            }
        },

        toggleAgree () {
            if (this.agree == false) {
                this.agree = true;
            } else {
                this.agree = false;
            }
        },

        focusNext(e) {
            const inputs = Array.from(e.target.form.querySelectorAll('input'));
            const index = inputs.indexOf(e.target);

            if (index < inputs.length) {
                inputs[index + 1].focus();
            }
        }

    },

    async mounted () {
        this.$Progress.start();
        await axios.get('api/client').then(({ data }) => (
            this.client = data,
            this.skeleton = false
        ));
        this.$Progress.finish();
    }
}
</script>

<style scoped>
.content-wrapper {
  margin-left: 0 !important;
  min-height: 0px !important;
}
.skeleton {
  width: 100% !important;
}
</style>
