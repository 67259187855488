<template>
  <div class="content-wrapper register-page">
    <div class="login-box">
      <div class="login-logo">
        <img :src="this.$client_picture_baseurl + 'logo.png'" width="100px" height="100px" alt="" class="brand-image img-circle elevation-3 mb-4" style="opacity: 1.0"><br/>
        <router-link to="/login"><b>{{ this.client.appname }}</b></router-link><skeleton-loader-vue v-show="skeleton" class="skeleton" type="rect" :rounded="true" :width="200" :height="51" animation="fade" />
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg" v-if="loading == true"><i class="spinner-border spinner-border-sm"></i> Verifying Email...</p>
          <p class="login-box-msg" v-else-if="loading == false && verified == true "><i class="fa fa-check-circle text-success"></i> Email is verified.<br>Redirecting to <router-link to="/login">login</router-link> page in {{ countDown }} seconds...</p>
          <p class="login-box-msg" v-else-if="loading == false && verified == false "><i class="fa fa-times-circle text-danger"></i> Invalid verification code.<br>Redirecting to <router-link to="/login">login</router-link> page in {{ countDown }} seconds...</p>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Verify',

  data() {
    return {
      countDown : 10,
      client: {},
      loading: true,
      verified: false,
      code: this.$route.query.code,
    }
  },

  methods: {
    countDownTimer() {
        if(this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        } else {
            this.$router.push('/login');
        }
    },

    async verifyCode() {
        this.loading = true;
        this.verified = false;

        const data = {
            verification_code: this.code,
        };

        await axios.post('api/verify', data).then((response) => {

        if(response.data.verified) {
            this.$swal.fire({
                icon: 'success',
                title: 'Verification success! Your account will be activated within 24 hrs.',
            })

            this.loading = false;
            this.verified = true;

            this.countDownTimer();
        } else {
            this.$swal.fire({
                icon: 'error',
                title: 'Invalid verification code',
            });

            this.loading = false;
            this.verified = false;

            this.countDownTimer();
        }  
        }).catch(()=>{
            this.$swal.fire({
                icon: 'error',
                title: 'Invalid verification code',
            })

            this.loading = false;
            this.verified = false;

            this.countDownTimer();
        });
    }
  },

  async mounted () {

    this.$Progress.start();

    await axios.get('api/client').then(({ data }) => (this.client = data));

    setTimeout(() => {
        this.verifyCode();
    }, 4000);

    this.$Progress.finish();
  }

}
</script>

<style scoped>
.content-wrapper {
  margin-left: 0 !important;
  min-height: 0px !important;
}
.log-box {
  width: 360px;
  margin: 0 auto 0;
  padding-top: 10%;
}
.content-wrapper {
  margin-left: 0 !important;
}
.login-box-msg, .register-box-msg {
    padding: 0 20px 0px !important;
}
</style>
