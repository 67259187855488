<template>
  <div class="content-wrapper login-page">
    <div class="login-box">
      <div class="login-logo">
        <img :src="this.$client_picture_baseurl + 'logo.png'" width="100px" height="100px" alt="" class="brand-image img-circle elevation-3 mb-4" style="opacity: 1.0"><br/>
        <router-link to="/login"><b>{{ this.client.appname }}</b></router-link><skeleton-loader-vue v-show="skeleton" class="skeleton" type="rect" :rounded="true" :width="200" :height="51" animation="fade" />
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Sign in to start your session</p>
          <form @submit.prevent="handleSubmit">
            <div class="input-group mb-3">
              <input v-model="email" type="email" class="form-control" placeholder="Email">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fa fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input id="input-password" v-model="password" type="password" class="form-control" placeholder="Password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <i v-if="showpassword != false" @click="toggleShowPassword" class="fa fa-eye-slash text-primary cursor-pointer"  v-tooltip="{
                                                                content: '<b>Hide password</b>',
                                                                placement: 'right',
                                                                classes: ['info'],
                                                                targetClasses: ['it-has-a-tooltip'],
                                                                offset: 10,
                                                                delay: {
                                                                  show: 100,
                                                                  hide: 300,
                                                                },
                                                              }"></i>
                  <i v-else @click="toggleShowPassword" class="fa fa-eye text-primary cursor-pointer" v-tooltip="{
                                                                content: '<b>Show password</b>',
                                                                placement: 'right',
                                                                classes: ['info'],
                                                                targetClasses: ['it-has-a-tooltip'],
                                                                offset: 10,
                                                                delay: {
                                                                  show: 100,
                                                                  hide: 300,
                                                                },
                                                              }"></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary d-none">
                  <input type="checkbox" id="showpassword" @click="toggleShowPassword" v-model="showpassword">
                  <label for="showpassword">Show password</label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <button :disabled="loading" type="submit" class="btn btn-primary btn-block">
                  <i v-if="loading" class="spinner-border spinner-border-sm"></i> Log In
                </button>
              </div>
            </div>
          </form>
          <p class="mb-1">
            <router-link to="/forgot">Forgot password?</router-link>
          </p>
          <p class="mb-0">
            <router-link to="/register" class="text-center">Register new account</router-link>
          </p>
        </div>
      </div>
      <small v-if="!skeleton" class="d-flex justify-content-center text-secondary mt-3">Version {{ this.client.version }}</small>
      <span v-else class="d-flex justify-content-center text-secondary mt-3"><skeleton-loader-vue type="rect" :rounded="true" :width="60" :height="12" animation="fade" /></span>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import setAuthHeader from '../utils/setAuthHeader'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Login',

  components: {
    VueSkeletonLoader,
  },

  data() {
    return {
      client: {},
      email: '',
      password: '',
      showpassword: false,
      loading: false,
      skeleton: true,
    }
  },

  methods: {
    handleSubmit() {
      this.loading = true;

      const credentials = {
          email: this.email,
          password: this.password
      };

      axios.post("api/login", credentials).then((response) => {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('avatar', response.data.user.photo)
            setAuthHeader(response.data.token);
            this.$store.dispatch('user', response.data.user)
            this.$router.push('/dashboard')
            this.loading = false;
          }            
        ).catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: err.response.data.message,
          })
          this.loading = false;
        });
    },

    toggleShowPassword () {
        var show = document.getElementById('input-password')
        if (this.showpassword == false) {
            this.showpassword = true;
            show.type = "text";
        } else {
            this.showpassword = false;
            show.type = "password";
        }
    }

  },

  async mounted () {
    this.$Progress.start();
    await axios.get('api/client').then(({ data }) => (
      this.client = data,
      this.skeleton = false
    ));
    this.$Progress.finish();
  }
}
</script>

<style scoped>
.content-wrapper {
  margin-left: 0 !important;
  min-height: 0px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.skeleton {
  width: 100% !important;
}
</style>
